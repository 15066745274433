import lozad from 'lozad';

import '@fontsource/gentium-book-basic/latin-700.css'; // Weight 700.

// gatsby-browser.js
const { observe } = lozad('.lozad', {
  loaded: (el) => {
    const { objectFit: finalObjectFit } = el.dataset;

    /**
     * TODO：
     * 这里是为了解决loading占位图不会适应，铺满所有尺寸的图片的问题，
     * 可以先将loading图的object-fit设置为cover,
     * 后面通过设置data-object-fit设置最终图片的object-fit
     */
    if (el.nodeName === 'IMG' && el instanceof HTMLImageElement) {
      el.onload = () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      };
    } else if (el.nodeName === 'VIDEO' && el instanceof HTMLVideoElement) {
      el.addEventListener('loadeddata', () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      });
    } else {
      el.classList.add('fade');
    }
  },
});

export const onInitialClientRender = () => {};

export const onRouteUpdate = ({ location }) => {
  observe();
};
